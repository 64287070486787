import React from "react";
import { ComponentContainer } from "../styled.js";
import { BandContainer } from "../styled.js";
import alinenImage from "../images/alinen.jpg";

const Band = () => {
  return (
    <div id="band">
      <ComponentContainer>
        <br />
        <br />
        <h2>Alinen</h2>
        <BandContainer>
          <p>
            Alinen cultivates a blend of trip hop, electronica and ambient that has its roots in traditional folk and Fennoscandian soil.
            The band is formed by three terrestrials, Toni Haapanen, Lauri Kajander and Heikki Pesu. The Finnish word 'Alinen' means underworld.
            So far, Alinen has released five singles. A full-length album is in the making.
            <br />
          </p>

          <img src={alinenImage} alt="Alinen" />
        </BandContainer>
        <br />
        <br />
      </ComponentContainer>
    </div>
  );
};

export default Band;
