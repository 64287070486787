import styled from "styled-components";
// import headerimage from "./images/alinen-playhouse.png";
import headerimage from "./images/sammal.jpg";
// import headerimage from "./images/headerimage.png";
import concertsBgImg from "./images/liveimage.png";
import releasesBgImg from "./images/sumukuva.jpg";
import contactBgImg from "./images/luola.jpg";
import { FaBars } from "react-icons/fa";

export const Bars = styled(FaBars)`
  display: none;
  color: #c7c5c5;

  @media screen and (max-width: 992px) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const BandContainer = styled.div`
  p {
    /* max-width: 250px; */
    max-width: 100%;
    /* max-height: 138.5px; */
    width: auto;
    height: auto;
  }
  img {
    max-width: 100%;
    opacity: 0.7;
    /* max-width: 250px; */
    /* max-height: 138.5px; */
    width: auto;
    height: auto;
  }
  @media (min-width: 1000px) {
    display: flex;
    p {
      display: block;
      max-width: 600px;
      max-height: 277px;
      width: auto;
      height: auto;
    }
    img {
      margin-left: 40px;
      display: block;
      max-width: 500px;
      max-height: 277px;
      width: auto;
      height: auto;
      margin-bottom: 20px;
    }
  }
`;

export const ReleasesBg = styled.div`
  z-index: 4;
  border: 1px solid #000;
  background-image: url(${releasesBgImg});
`;

export const ConcertsBg = styled.div`
  z-index: 4;
  border: 1px solid #000;
  background-image: url(${concertsBgImg});
`;

export const ContactBg = styled.div`
  z-index: 4;
  border: 1px solid #000;
  background-image: url(${contactBgImg});
  /* background-size: auto; */
  background-size: 1920px auto;
`;

export const ComponentContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    margin-left: 80px;
    margin-right: 80px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 1200px) {
    margin-left: 150px;
    margin-right: 150px;
  }
`;

export const HeaderContainer = styled.div`
  /* display: flex;
  text-indent: 10px;
  padding: 1em;
  background-image: url(${headerimage});
  /* background-size: auto; */
  /* background-repeat:no-repeat;
  background-size: cover;
  justify-content: center;
  max-width: 100%;
  max-height: 20%; */
    background: url(${headerimage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const Navigation = styled.div`
  position: absolute;
  background: #a3c8a3;
  padding: 1em;
`;

export const Button = styled.button`
  background: black;
  font-size: 1em;
  color: #696969;
  padding: 0.25em 1em;
  border: 2px solid #4e664e;
  border-radius: 3px;
`;

export const Table = styled.table`
  caption-side: top;
  border: none;
  border-collapse: collapse;

  caption-side: bottom;
  td,
  th {
    border: none;
  }

  td {
    padding: 5px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
    }
    :hover {
      background-color: #759975;
    }
  }
  thead > tr {
    background-color: #c2c2c2;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const ImageContainer = styled.div`
  z-index: 5;

  margin-top: 16px;
  height: 300px;
`;

export const StyledImage = styled.div`
  z-index: 5;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const FooterStyle = styled.div`
  background-color: black;
  color: #696969;
  position: fixed;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  z-index: 3;
  text-align: center;
  box-shadow: 1px 1px 2px #696969, 0 0 3px green;
`;
